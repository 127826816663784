.BusinessLocator-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
}

@media (max-width: 768px) {
  .BusinessLocator-container {
    flex-direction: column;
  }
}
