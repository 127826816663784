.BusinessLocator-panel-mobile {
  display: none;
}

@media (max-width: 768px) {
  .BusinessLocator-panel-mobile {
    position: absolute;
    top: calc(100% - 100px);
    min-height: calc(100% - 250px);
    max-height: calc(100% - 250px);
    z-index: 10;
    display: block;
    width: 100%;
    background-color: #f2f2f2;
    padding: 0px 20px 20px 20px;
    transition: top 0.3s ease-in-out;
    &.BusinessLocator-panel-mobile--expand {
      &:not(.BusinessLocator-panel-mobile--hide) {
        top: 250px;
        overflow: auto;
      }
    }
    &.BusinessLocator-panel-mobile--hide {
      top: 100%;
    }
    .BusinessLocator-panel-mobile-business-hr {
      width: 50%;
      margin: 10px 25%;
    }
  }
}
