.BusinessLocator-panel {
  width: 650px;
  background-color: #f2f2f2;
  padding: 50px;
  max-height: 100%;
  z-index: 2;
  .BusinessLocator-panel-title {
    font-size: 1.75rem;
  }
  .BusinessLocator-panel-searchbar-container {
    width: 100%;
  }
  .BusinessLocator-panel-search-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
    transition: max-height 0.3s ease-in-out;
    .BusinessLocator-panel-search-info-quickinfo {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &:not(:first-child) {
        display: none;
      }
      .BusinessLocator-panel-search-info-btn-list,
      .BusinessLocator-panel-search-info-count {
        font-weight: bold;
      }
      .BusinessLocator-panel-search-info-filter {
        font-size: 1.5rem;
        margin-left: 20px;
        color: #27afbf;
        cursor: pointer;
      }
    }
  }
  .BusinessLocator-panel-business-list {
    max-height: calc(100% - 170px);
    overflow: auto;
    .BusinessLocator-panel-business--selected {
      border: 1px solid #fdc83d;
    }
  }
}

@media (max-width: 768px) {
  .BusinessLocator-panel {
    width: 100%;
    padding: 15px 15px 10px 15px;
    max-height: 100%;
    .BusinessLocator-panel-title {
      font-size: 1.75rem;
    }
    .BusinessLocator-panel-searchbar-container {
      width: 100%;
    }
    .BusinessLocator-panel-search-info {
      justify-content: space-around;
      padding: 15px 15px 0px 15px;
      .BusinessLocator-panel-search-info-quickinfo {
        justify-content: center;
        &:not(:first-child) {
          display: flex;
        }
        &:first-child {
          border-right: 1px solid rgba(0, 0, 0, 0.25);
        }
        .BusinessLocator-panel-search-info-btn-list {
          display: block;
        }
        .BusinessLocator-panel-search-info-count {
          font-weight: bold;
        }
      }
    }
    .BusinessLocator-panel-business-list {
      position: absolute;
      top: 195px;
      bottom: 0;
      right: 100%;
      background-color: white;
      transition: right 0.3s ease-in-out;
      // z-index: 1;
      &.BusinessLocator-panel-business-list--visible {
        right: 0;
      }
    }
  }
}
