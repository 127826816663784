.BusinessLocator-searchbar {
  width: 100%;
  position: relative;
  input {
    width: calc(100% - 15px);
    height: 60px;
    padding: 40px 70px;
    font-size: 1.5rem;
  }
  .BusinessLocator-searchbar-localisation-icon {
    position: absolute;
    top: 20px;
    left: 0;
    width: 60px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  .BusinessLocator-searchbar-search-icon {
    position: absolute;
    top: 20px;
    right: 15px;
    font-size: 25px;
    width: 60px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fdc444;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .BusinessLocator-searchbar {
    input {
      width: 100%;
      font-size: 1rem;
      padding: 40px 50px 40px 70px;
    }
    .BusinessLocator-searchbar-search-icon {
      position: absolute;
      top: 20px;
      right: 15px;
      font-size: 25px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fdc444;
      cursor: pointer;
    }
  }
}
