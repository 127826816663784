.BusinessLocator-map {
  width: calc(100% - 650px);
  height: 100%;
  .BusinessLocator-map-message {
    display: none;
  }
}

@media (max-width: 768px) {
  .BusinessLocator-map {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    .BusinessLocator-map-message {
      position: absolute;
      height: 100%;
      width: 100%;
      pointer-events: none;
      z-index: 5; // required to overlay map controls, too (z-index: 2)
      opacity: 0;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white;
      font-size: 1.4em;
      line-height: 130%;
      padding: 1rem;
      transition: opacity 0.5s;
    }
  }
}
