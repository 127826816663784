.BusinessLocator-business-mobile {
  background-color: white;
  .BusinessLocator-business-mobile-header {
    display: flex;
    padding: 20px;
    max-width: 100%;
    .BusinessLocator-business-mobile-pic {
      width: 120px;
      min-width: 120px;
      height: 120px;
      margin-right: 20px;
      background-color: lightgray;
    }
    .BusinessLocator-business-mobile-info {
      flex: 1;
      overflow: hidden;
      .BusinessLocator-business-mobile-id {
        display: flex;
        justify-content: space-between;
        .BusinessLocator-business-mobile-name {
          max-width: 100%;
          font-weight: bold;
        }
      }
      .BusinessLocator-business-mobile-distance {
        color: #fdc444;
      }
      .BusinessLocator-business-mobile-status {
        display: flex;
        align-items: baseline;
        max-width: 100%;
        margin-bottom: 20px;
        .BusinessLocator-business-mobile-isopen {
          width: 12px;
          height: 12px;
          min-width: 12px;
          min-height: 12px;
          border-radius: 50%;
          background-color: darkred;
          margin-right: 5px;
          &.BusinessLocator-business-mobile-isopen--opened {
            background-color: #27afbf;
          }
        }
        .BusinessLocator-business-mobile-status-text {
          .BusinessLocator-business-mobile-status-text-closed {
            color: darkred;
            font-weight: bold;
            margin-right: 5px;
          }
          .BusinessLocator-business-mobile-status-text-opened {
            display: block;
            color: #27afbf;
            font-weight: bold;
            margin-right: 5px;
          }
        }
      }
    }
    .BusinessLocator-business-mobile-expand {
      margin-left: 20px;
      font-weight: bold;
      color: #fdc444;
      cursor: pointer;
      user-select: none;
      &.BusinessLocator-business-mobile-expand--open {
        font-size: 90px;
      }
    }
  }
  .BusinessLocator-business-mobile-body {
    .BusinessLocator-business-mobile-address {
      max-width: 100%;
      color: darkgray;
      font-size: 0.9rem;
      line-height: 1rem;
      margin-left: 40px;
    }
    .BusinessLocator-business-mobile-body-main {
      padding: 0 40px;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .BusinessLocator-business-mobile-openinghours {
        flex: 1;
        .BusinessLocator-business-mobile-openinghours-title {
          font-weight: bold;
          font-size: 0.85rem;
          padding-left: 5px;
        }
        .BusinessLocator-business-mobile-openinghours-timetable {
          color: darkgrey;
          font-size: 0.8rem;
          .BusinessLocator-business-mobile-openinghours-header {
            vertical-align: top;
          }
          td {
            padding: 0 5px;
          }
        }
      }
      .BusinessLocator-business-mobile-body-extra {
        flex: 1;
        display: flex;
        justify-content: space-around;
        margin: 20px 0 10px 0;
        .BusinessLocator-business-mobile-body-extra-icons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .BusinessLocator-business-mobile-body-extra-icon {
            font-size: 50px;
            color: #27afbf;
          }
          .BusinessLocator-business-mobile-body-extra-label {
            color: darkgrey;
          }
        }
      }
    }
    .BusinessLocator-business-mobile-body-actions {
      padding: 0 40px;
    }
    .BusinessLocator-business-mobile-action {
      display: flex;
      align-items: center;
      cursor: pointer;
      .BusinessLocator-business-mobile-action-icon {
        color: #fdc444;
        margin-right: 10px;
        font-size: 1.5rem;
      }
      .BusinessLocator-business-mobile-action-label {
        color: #fdc444;
      }
    }
  }
}
