.BusinessLocator-business {
  background-color: white;
  margin: 20px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .BusinessLocator-business-header {
    display: flex;
    padding: 20px;
    max-width: 100%;
    max-height: 160px;
    &.BusinessLocator-business-header--opened {
      max-height: unset;
    }
    .BusinessLocator-business-pic {
      width: 120px;
      min-width: 120px;
      height: 120px;
      margin-right: 20px;
      background-color: lightgray;
      cursor: pointer;
    }
    .BusinessLocator-business-info {
      flex: 1;
      overflow: hidden;
      &.BusinessLocator-business-info--opened {
        overflow: unset;
      }
      .BusinessLocator-business-id {
        display: flex;
        cursor: pointer;
        .BusinessLocator-business-name {
          max-width: 100%;
          font-weight: bold;
        }
        .BusinessLocator-business-distance {
          color: #fdc444;
          margin-left: 5px;
        }
      }
      .BusinessLocator-business-status {
        display: flex;
        align-items: baseline;
        max-width: 100%;
        margin-bottom: 20px;
        .BusinessLocator-business-isopen {
          width: 12px;
          height: 12px;
          min-width: 12px;
          min-height: 12px;
          border-radius: 50%;
          background-color: darkred;
          margin-right: 5px;
          &.BusinessLocator-business-isopen--opened {
            background-color: #27afbf;
          }
        }
        .BusinessLocator-business-status-text {
          .BusinessLocator-business-status-text-closed {
            color: darkred;
            font-weight: bold;
            margin-right: 5px;
          }
          .BusinessLocator-business-status-text-opened {
            color: #27afbf;
            font-weight: bold;
            margin-right: 5px;
          }
        }
      }
      .BusinessLocator-business-address {
        max-width: 100%;
        color: darkgray;
        font-size: 0.9rem;
        line-height: 1rem;
      }
    }
    .BusinessLocator-business-expand {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      font-size: 1.25rem;
      color: #fdc444;
      cursor: pointer;
      user-select: none;
      &.BusinessLocator-business-expand--open {
        max-height: 120px;
      }
    }
  }
  .BusinessLocator-business-body {
    overflow: hidden;
    .BusinessLocator-business-body-main {
      padding: 0 20px;
      display: flex;
      .BusinessLocator-business-openinghours {
        flex: 1;
        .BusinessLocator-business-openinghours-title {
          font-weight: bold;
          font-size: 0.85rem;
          padding-left: 5px;
        }
        .BusinessLocator-business-openinghours-timetable {
          color: darkgrey;
          font-size: 0.8rem;
          .BusinessLocator-business-openinghours-header {
            vertical-align: top;
          }
          td {
            padding: 0 5px;
          }
        }
      }
      .BusinessLocator-business-body-extra {
        flex: 1;
        display: flex;
        justify-content: space-around;
        .BusinessLocator-business-body-extra-icons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .BusinessLocator-business-body-extra-icon {
            font-size: 50px;
            color: #27afbf;
          }
          .BusinessLocator-business-body-extra-label {
            color: darkgrey;
          }
        }
      }
    }
    .BusinessLocator-business-body-actions {
      padding: 10px;
      display: flex;
      justify-content: space-around;
      .BusinessLocator-business-action {
        display: flex;
        align-items: center;
        cursor: pointer;
        .BusinessLocator-business-action-icon {
          color: #fdc444;
          margin-right: 10px;
          font-size: 1.5rem;
        }
        .BusinessLocator-business-action-label {
          color: #fdc444;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .BusinessLocator-business {
    .BusinessLocator-business-header {
      .BusinessLocator-business-pic {
        width: 80px;
        min-width: 80px;
        height: 80px;
      }
      .BusinessLocator-business-expand {
        align-items: baseline;
      }
      .BusinessLocator-business-info {
        .BusinessLocator-business-id {
          flex-direction: column;
          .BusinessLocator-business-name {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 180px;
            white-space: nowrap;
          }
          .BusinessLocator-business-distance {
            margin-left: 0;
          }
        }
        .BusinessLocator-business-status {
          margin-bottom: 5px;
        }
      }
    }
  }
}
