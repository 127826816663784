.BusinessLocator-panel-filters {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-left: 20px;
  &.BusinessLocator-panel-filters--opened {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .BusinessLocator-panel-filter {
    &:not(:first-child) {
      margin-left: 20px;
    }
    &.BusinessLocator-panel-filter--activated {
      color: #fdc83d;
    }
    &.BusinessLocator-panel-filter-check,
    &.BusinessLocator-panel-filter-sort {
      user-select: none;
      cursor: pointer;
      &:hover {
        color: #fdc83d;
      }
    }
    select {
      background-color: transparent;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .BusinessLocator-panel-filters {
    .BusinessLocator-panel-filters--opened {
      margin-bottom: 10px;
      margin-top: 0;
    }
  }
  .BusinessLocator-panel-search-info-filter {
    font-size: 1.5rem;
    margin-left: 20px;
    color: #27afbf;
    cursor: pointer;
  }
}
